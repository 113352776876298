import Vue from 'vue'
import Vuex from 'vuex'
import video from './modules/video';
import constants from './modules/constants';
import news from './modules/news';
import presentations from './modules/presentations';
import utils from './modules/utils';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      video,
      constants,
      utils,
      news,
      presentations,
    }
})
