<template>
  <div class="page">
      <h2 v-html="title" style="width:100%;float:left;padding-bottom:2%;"></h2>

      <div class="video-container">
        <iframe class="video-iframe" frameborder="0" :src="video.url" allowfullscreen></iframe>
      </div>

      <!--<div class="share-container" style="display:none;">
        <div class="social_share_buttons">
          <div class="share_button wa_mobile_share"><img src="https://sawbo-animations.org/images/social_media/wa_200x150.png" title="Share on WhatsApp" alt="Share on WhatsApp" width="100px" height="75px"></div>
          <div class="share_button wa_share"><img src="https://sawbo-animations.org/images/social_media/wa_200x150.png" title="Share on WhatsApp" alt="Share on WhatsApp" width="100px" height="75px"></div>
          <div class="share_button tw_share"><img src="https://sawbo-animations.org/images/social_media/tw_200x150.png" title="Share on Twitter" alt="Share on Twitter" width="100px" height="75px"></div>
          <div class="share_button fb_share"><img src="https://sawbo-animations.org/images/social_media/fb_200x150.png" title="Share on Facebook" alt="Share on Facebook" width="100px" height="75px"></div>
          <div class="share_button share_activate"><br><span id="share_activate_txt">Share This Page</span></div>
        </div>
      </div>-->
 
      <div class="video-about">
        <div class="video-language">Language: {{ video.language }}</div>
        <div class="video-country">Country: {{ video.country }}</div>
        <div class="video-subtitle" v-if="video.subtitles">Subtitles: {{ video.subtitles }}</div>
        <div class="video-subtitle" v-if="video.custom">{{ video.custom }}</div>
        <div class="video-description" v-html="description"></div>
      </div>
 
      <div class="video-select">
            <div class="language-selector-text">Select a Language: </div>
            <select name="lang" class="language-selector" 
                @change="routeToVideo(selectedVideo)"
                v-model="selectedVideo"
            >
                <option value="">--Language--</option>
                <option 
                    v-for="v in languagesList"
                    :key="v.id"
                    :value="v.id"
                >
                    {{ constructOptionText(v) }}
                </option>
            </select>
      </div>

      <hr class="blue-divider hr-select-download">
    
      <div class="video-download">
        <div class="download-text" v-html="downloadText"></div>
        <div class="download-container">
            <a 
                v-for="f in downloadFiles"
                :key="f"
                :href="downloadLink(f)"
                target="_blank"
                class="download-link"
            >
                <div class="download-button">
                    <div class="download-button-image">
                        <img :src="fileType(f).image" width="100%" :alt="fileType(f).alt">
                    </div>
                    <div class="download-button-text">
                        {{ fileType(f).device }}
                        <br>
                        {{ "(" + fileType(f).fileType + ")" }}
                    </div>
                </div>
            </a>
        </div>
      </div>

      <hr class="blue-divider hr-download-disclaimer">

      <div class="download-disclaimer">
          <div class="download-disclaimer-text-l">
            To download animations directly onto an android phone, <a href="https://play.google.com/store/apps/details?id=edu.mcu.sawbo" class="black_link">download the SAWBO app at Google Play</a>.
          </div>
          <div class="download-disclaimer-text-r">
            We do not support iOS at this time. If you have an iOS device you may have trouble directly downloading our animations from the above links.
          </div>
      </div>

      <hr class="blue-divider">

      <!--<div class="subscribe-button">
        <div class="donateSubscribeButtons subscribeButton">
            <div class="donateSubscribeTextContainer">
                <div class="donateSubscribeTitle">
                    SUBSCRIBE
                </div>
                <div class="donateSubscribeDescription">
                    Please subscribe to our newsletter to stay up to date on all of our new content.
                </div>
            </div>
            <div class="donateSubscribePushToPlay">
                <a href="http://eepurl.com/6Pmp9" target="_blank">
                    <img src="https://sawbo-animations.org/images/pushToPlay/pushToPlay_white.png" width="75%"/>
                </a>
            </div>
        </div>
      </div>-->

      <div class="sdg-section" v-if="hasSDG">
        <div class="sdg-text">This animation fulfills the following Sustainable Development Goals.</div>
        <div class="sdg-img">
            <img src="https://sawbo-animations.org/images/sdg/E_SDG_logo_No UN Emblem_horizontal_rgb.png" width="100%">
        </div>
        <div 
            class="sdg-box"
            v-for="s in videoSDG"
            :key="s"
        >
            <img :src="sdgImage(s)" width="100%">
        </div>
      </div>

      <hr class="blue-divider">

  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Video',
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description
        },
        { property: "og:title", content: this.title },
        { property: "og:image", content: this.topicImg },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "video" }
      ],
    };
  },
  components: {
  },
  data() {
      return {
          selectedVideo: this.videoID,
      }
  },
  props: {
    videoID: {
        type: String,
        required: true,
    }
  },
  computed: {
      ...mapGetters([
        'videos',
        'topics',
        'fileType',
        'sdgArray',
      ]),
      video() {
        if (this.videoID) {
          return this.videos[this.videoID];
        }
        return {};
      },
      topic() {
        if (this.video && this.video.topic) {
          return this.topics[this.video.topic];
        }
        return {};
      },
      topicImg() {
        return "https://sawbo-animations.org/images/videoThumbnails/" + this.topic.image;
      },
      title() {
          return this.topic.title;
      },
      description() {
          return this.topic.description;
      },
      languagesList() {
          let l = [];
          if (this.topic && this.topic.videos) {
              this.topic.videos.forEach(v => {
                  l.push(this.videos[v]);
              });
          }
          return l;
      },
      downloadsAvailable() {
          if (this.video && this.video.files && this.video.files.length > 0) {
              return true;
          }
          return false;
      },
      downloadText() {
          if (this.downloadsAvailable) {
              return "Download:";
          }
          return "Not Available For Download<br><br>";
      },
      downloadFiles() {
          if (this.downloadsAvailable) {
              return this.video.files;
          }
          return [];
      },
      videoSDG() {
          let arr = [];
          if (this.topic && this.topic.sdg) {
              for (let s = 1; s<= 17; s++) {
                if (this.topic.sdg[s]) {
                    arr.push(s);
                }
              }
          }
          return arr;
      },
      hasSDG() {
          if (this.videoSDG.length > 0) {
              return true;
          }
          return false;
      }
  },
  methods: {
      constructOptionText(video) {
          let t = video.language + " (" + video.country + ")";
          if (video.subtitles) {
              t += " " + video.subtitles + " Subtitles";
          }
          if (video.custom) {
              t += " " + video.custom;
          }
          return t;
      },
      routeToVideo(videoID) {
          if (videoID != "" && videoID !== this.videoID) {
              //console.log('routing to video: '+videoID);
              this.$router.push("/video/"+videoID);
          }
      },
      downloadLink(file) {
          return "https://sawbo-animations.org/download_aws.php?video="+file+"&id="+this.videoID
      },
      sdgImage(sdg) {
          if (sdg < 10) {
              return "https://sawbo-animations.org/images/sdg/E_SDG%20goals_0" + sdg + ".png";
          }
          return "https://sawbo-animations.org/images/sdg/E_SDG%20goals_" + sdg + ".png";
      }
  },
  created() {
      //console.log(this.fileType("EN_English_USA_PHLJerryCan_Final_Light.3gp"));
  }
}
</script>

<style scoped>
.blue-divider {
  color: #3B5783;
  margin: 4% 0;
}

.video-container {
    width: 100%;
    float: left;
    margin: 0;
    padding-bottom: 2%;
}
.video-iframe {
    width: 100%;
    height: 450px;
    padding: 0;
    margin: 0;
}

.video-about {
    width: 100%;
    float: left;
    padding: 2.5%;
    background-color: #3B5783;
    color: #FFFFFF;
}
.video-language {
    display: inline-block;
    padding-right: 4%;
}
.video-country {
    display: inline-block;
    padding-right: 4%;
}
.video-subtitle {
    display: inline-block;
    padding-right: 4%;
}
.video-description {
    width: 100%;
    display: block;
    padding-top: 4%;
}

.video-select {
    font-size: 1.2em;
    width: 100%;
    padding: 4% 0;
    float: left;
    /*text-align: right;*/
}
.language-selector-text {
    display: inline-block;
    padding-right: 2%;
    padding-bottom: 0%;
}
.language-selector {
    width: 50%;
    display: inline-block;
    font-size: 1em;
}

.video-download {
    width: 100%;
    float: left;
    padding: 0 0 4% 0;
}
.download-text {
    width: 100%;
    font-size: 1.2em;
}
.download-container {
    width: 100%;
}
.download-link {
    width: 23%;
    padding-left: 2%;
    display: inline-block;
    text-decoration: none;
}
.download-button {
    width: 100%;
}
.download-button-image {
    width: 100%;
}
.download-button-text {
    font-size: 1.2em;
    text-align: center;
    color: #000000;
    text-decoration: none;
}

.hr-download-disclaimer {
    display: none;
}

.download-disclaimer {
    width: 100%;
    float: left;
    padding-bottom: 3%;
}
.download-disclaimer-text-l {
    width: 100%;
    padding-right: 0%;
    float: left;
    padding-bottom: 2%;
}
.download-disclaimer-text-l a {
    color: #3B5783;
}
.download-disclaimer-text-r {
    width: 100%;
    padding-left: 0;
    float: left;
    padding-bottom: 2%;
}


/*Donate and Subscribe buttons below*/
.subscribe-button {
    width: 100%;
    float: left;
    padding-bottom: 4%;
}
.donateSubscribe{
	float: right;
	width: 50%;
	padding-top: 5%;
}
.donateSubscribeWhiteSpace{
	width: 100%;
	float: left;
	height: 50px;
}
.bottomDonateButton{
	background-color: #71b732;
}
.subscribeButton{
	background-color: #3B5783;
}
.donateSubscribeButtons{
	float: left;
	width: 100%;
}
.donateSubscribeTextContainer{
	float: left;
	width: 75%;
	color: #FFFFFF;
}
.donateSubscribeTitle{
	float: left;
	padding: 5% 5% 2% 5%;
	font-size: 2em;
	font-family: 'Bebas Neue', 'bebas_neueregular', sans-serif;
}
.donateSubscribeDescription{
	float: left;
	padding: 0 5% 5% 5%;
	font-size: 1em;
	font-family: 'BentonSans Medium', sans-serif;
}
.donateSubscribePushToPlay{
	float: right;
	width: 20%;
	padding: 8% 0 8% 0;
}
.download_disclaimer{
	float: left;
	width: 47%;
	padding-top: 5%;
	padding-right: 3%;
	font-size: 1em;
}


.sdg-section {
	width: 100%;
    float: left;
    padding-bottom: 2%;
}
.sdg-text {
    width: 100%;
    float: left;
    text-align: center;
}
.sdg-img{
	float: left;
	width: 100%;
    padding: 0 10%;
}
.sdg-img img{
	width: 100%;
}
.sdg-box{
	float: left;
	width: 18%;
	padding: 0 2.5% 2.5% 0;
}
.sdg-box a, .sdg-box img{
	width: 100%;
	float: left;
}
.sdg-end-box{
	float: left;
	width: 18%;
	padding-right: 0px;
}
.sdg-end-box a, .sdg-end-box img{
	width: 100%;
	float: left;
}




/*.v_t_r {
	width: 50%;
	float: right;
	font-family: 'BentonSans', 'Helvetica', sans-serif;
}
.social_share_buttons {
	margin-top: 50px;
	height: 75px;
	float: left;
	width: 100%;
}
.share_button {
	font-family: 'BentonSans Medium', 'Helvetica', sans-serif;
	width: 19%;
	height: 100%;
	float: right;
	display: none;
	border-left: 1px solid #adb0af;
	cursor: pointer;
}
.share_activate {
	font-family: 'BentonSans Medium', 'Helvetica', sans-serif;
	background-color: #3B5783;
	color: #FFFFFF;
	width: 20%;
	height: 100%;
	float: right;
	text-align: center;
	cursor: pointer;
}
.share_activate_txt {
	font-family: 'BentonSans Medium', 'Helvetica', sans-serif;
	margin-left: auto;
	margin-right: auto;
}*/

@media only screen and (min-width: 1050px) {
.video-container {
    width: 50%;
    height: 375px;
    float: left;
    margin: 0;
    padding-bottom: 0;
}
.video-iframe {
    width: 100%;
    height: 375px;
    padding: 0;
    margin: 0;
}

.video-about {
    width: 50%;
    height: 375px;
    float: left;
    padding: 2.5%;
    background-color: #3B5783;
    color: #FFFFFF;
}

.video-select {
    font-size: 1.2em;
    width: 35%;
    padding: 4% 0 4% 0;
    float: left;
}
.language-selector-text {
    display: block;
    padding-bottom: 2%;
}
.language-selector {
    width: 75%;
    display: block;
}

.hr-select-download {
    display: none;
}

.video-download {
    width: 65%;
    float: left;
    padding: 4% 0 4% 0;
}

.hr-download-disclaimer {
    display: block;
}

.download-disclaimer {
    width: 100%;
    padding-bottom: 3%;
    float: left;
}
.download-disclaimer-text-l {
    width: 50%;
    padding-right: 1%;
    padding-bottom: 0;
    float: left;
}
.download-disclaimer-text-r {
    width: 50%;
    padding-left: 1%;
    padding-bottom: 0;
    float: left;
}

}

@media only screen and (min-width: 1200px) {

}
</style>
