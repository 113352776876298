import sysOutDev from '../../data/sysOut';

let news;
let newsOrder;

if (process.env.NODE_ENV === 'development') {
    news = sysOutDev.news;
    newsOrder = sysOutDev.newsOrder;
} else {
    news = window.sysOut.news;
    newsOrder = window.sysOut.newsOrder;
}

const state = {
    news: news,
    newsOrder: newsOrder,
}

const mutations = {
}

const actions = {
}

const getters = {
    news: state => {
        return state.news;
    },
    newsList: state => {
        return state.newsOrder;
    },
    homeNews: state => {
        let arr = state.newsOrder;
        let arrLen = arr.length;
        if (arrLen > 2) {
            return arr.slice(0,2);
        } else {
            return arr;
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}