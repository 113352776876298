<template>
  <div id="app">
    <div class="main">
      <sawbo-rapid-header />
      <router-view />
      <contact />
      <sawbo-rapid-footer />
    </div>
  </div>
</template>

<script>
import SawboRapidFooter from './components/layout/SawboRapidFooter.vue'
import SawboRapidHeader from './components/layout/SawboRapidHeader.vue'
import Contact from './components/layout/Contact'

export default {
  name: 'App',
  components: {
    SawboRapidHeader,
    SawboRapidFooter,
    Contact
  }
}
</script>

<style>
#app {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #fafafa;
  float: left;
}
.main {
  background-color: #ffffff;
  width: 100%;
  margin: 0;
  padding: 0;
}
.page {
  width: 100%;
  padding: 2% 5% 0 5%;
  min-height: 70vh;
}

@media only screen and (min-width: 745px) {
.main {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}
}
</style>
