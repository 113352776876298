import sysOutDev from '../../data/sysOut';

let presentations;

if (process.env.NODE_ENV === 'development') {
    presentations = sysOutDev.presentations;
} else {
    presentations = window.sysOut.presentations;
}

const state = {
    presentations: presentations,
}

const mutations = {
}

const actions = {
}

const getters = {
    presentations: state => {
        return state.presentations;
    },
    presentationsList: state => {
        return Object.keys(state.presentations).reverse();
    },
    homePresentations: state => {
        let arr = Object.keys(state.presentations);
        let arrLen = arr.length;
        if (arrLen > 2) {
            return arr.slice(arrLen-2);
        } else {
            return arr;
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}