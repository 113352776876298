<template>
  <div class="page">
    <section class="about">
      <h2>About SAWBO <i>RAPID</i></h2>
      <p>
        Scientific Animations Without Borders (SAWBO) announces the Feed the
        Future SAWBO Responsive-Adaptive-Participatory Information Dissemination
        Scaling Program (SAWBO RAPID).
      </p>
      <p>
        Funded through a grant from Feed the Future, the U.S. Government's
        global hunger and food security initiative, SAWBO RAPID serves as an
        educational intervention program to disseminate crucial information
        related to COVID-19's secondary economic impacts, including disruption
        to trade, supply chains and markets.
      </p>
      <p>
        SAWBO RAPID will ensure that important COVID-19 information will be
        delivered across borders, cultures and literacy levels by quickly
        developing educational animations in local languages and using virtual
        dissemination platforms to reach remote and marginalized communities.
      </p>
      <p>
        The program also builds on SAWBO's research-to-impact program. The
        program, with over
        <a
          href="https://sawbo-animations.org/publications"
          target="_blank"
          style="color: #000"
          >33 publications</a
        >
        addressing research questions necessary to support SAWBO strategies, is
        an example of a cutting-edge approach where land grant institutions can
        take research outcomes to create meaningful impacts in a COVID-19 world.
        Prior to SAWBO RAPID, the SAWBO program had created over 90 animated
        topics in over 145 language variants.
      </p>
      <p>
        SAWBO videos can currently be found on TV stations in both West and East
        Africa, with over 43 million people known to have benefited from them.
      </p>
      <p>
        SAWBO RAPID's COVID-19 response sets the groundwork for a systems
        approach to educational responses to future global catastrophes and
        further democratizes information critical to surviving these critical
        times. SAWBO content, once created, is readily available for anyone or
        any group to use immediately for educational purposes only –
        circumventing delays that would occur if they were to have to create
        their own content to respond to crises. The SAWBO system is focused on
        providing accurate educational information for people of all literacy
        levels across as many language groups as possible – content that can be
        used by other individuals and groups around the world to have impact in
        this community in real time – allowing for a RAPID response to emerging
        challenges
      </p>
    </section>
    <hr class="blue-hr" />
    <section class="about">
      <p>
        SAWBO RAPID is a program run by the Scientific Animations Without
        Borders (SAWBO) Team. For more information about SAWBO
        <a href="https://sawbo-animations.org">please visit our website</a>.
      </p>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "About",
  metaInfo() {
    return {
      title: "About SAWBO RAPID",
      meta: [
        {
          name: "description",
          content: "Funded through a grant from Feed the Future, the U.S. Government's global hunger and food security initiative, SAWBO RAPID serves as an educational intervention program to disseminate crucial information related to COVID-19's secondary economic impacts, including disruption to trade, supply chains and markets."
        },
        { property: "og:title", content: "About SAWBO RAPID" },
        { property: "og:image", content: "https://sawbo-animations.org/images/social_media/SAWBO_FB_Share.png" },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "website" }
      ],
    };
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    hasNews() {
      if (this.homeNews.length > 0) {
        return true;
      }
      return false;
    },
    hasVideos() {
      if (this.homeVideos.length > 0) {
        return true;
      }
      return false;
    },
    hasPresentations() {
      if (this.homePresentations.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.about {
  padding-bottom: 2%;
}
.blue-hr {
  color: #3b5783;
  margin: 4% 0;
}
.about h2 {
  padding-bottom: 1.5%;
}
.about p {
  padding-bottom: 2.5%;
}
</style>
