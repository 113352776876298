<template>
    <div class="presentation-item-list">
        <presentation-item 
            v-for="p in presentationsList" :key="p"
            :presentationID="p"
            class="presentation-item"
        />
    </div>
</template>

<script>
import PresentationItem from './PresentationItem'

export default {
  name: 'PresentationsList',
  components: {
      PresentationItem,
  },
  props: {
    presentationsList: {
        type: Array,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
  },
  created() {
  }

}
</script>

<style scoped>
.presentation-item-list {
    width: 100%;
}
.presentation-item {
    width: 100%;
    display: inline-block;
    padding-bottom: 5%;
}

@media only screen and (min-width: 1200px) {
  .presentation-item {
        width: 50%;
        display: inline-block;
        padding-bottom: 5%;
    }
}

</style>
