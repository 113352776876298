<template>
    <div class="video-item">
        <router-link :to="videoLink">
            <img 
                class="video-item-image" 
                :src="topicImg"
            >
        </router-link>
        <h4 class="video-item-title" v-html="topic.title"></h4>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VideoItem',
  props: {
    videoID: {
        type: Number,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
    ...mapGetters([
      'videos',
      'topics',
    ]),
    video() {
        return this.videos[this.videoID];
    },
    topic() {
        if (this.video) {
            return this.topics[this.video.topic];
        }
        return {};
    },
    topicImg() {
        return "https://sawbo-animations.org/images/videoThumbnails/" + this.topic.image;
    },
    videoLink() {
        if (this.video) {
            return "video/" + this.video.id;
        } else {
            return "";
        }
    }
  },
  created() {
  }

}
</script>

<style scoped>
.video-item {
    width: 100%;
}
.video-item-image {
    width: 100%;
    padding-bottom: 2%;
    vertical-align: top;
}
.video-item-title {
    font-size: 1.2em;
}

@media only screen and (min-width: 745px) {
.video-item {
    width: 95%;
    padding-right: 5%;
}
.video-item-image {
    width: 100%
}
.video-item-title {
    font-size: .95em;
}
}
</style>
