import Vue from 'vue'
import VueRouter from 'vue-router'

// Pages
import Home                     from '../pages/Home';
import About                    from '../pages/About';
import News                    from '../pages/News';
import Presentations             from '../pages/Presentations';
import Videos                   from '../pages/Videos';
import Video                   from '../pages/Video';
import Disclaimer               from '../pages/Disclaimer';
// Redirect
//import Redirect                 from '../pages/Redirect';

Vue.use(VueRouter)

const routes = [
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path: '/news',
        name: 'news',
        component: News
    },
    {
        path: '/presentations',
        name: 'presentations',
        component: Presentations
    },
    {
        path: '/disclaimer',
        name: 'disclaimer',
        component: Disclaimer
    },
    {
        path: '/videos',
        name: 'videos',
        component: Videos
    },
    {
        path: '/video/:videoID',
        props: true,
        name: 'video',
        component: Video
    },
    {
        path: '/',
        name: 'home',
        component: Home
    },
    //Redirect
    /*{
        path: '*',
        name: 'redirect',
        component: Redirect
    }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
