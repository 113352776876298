<template>
    <iframe :src="twitterLink"></iframe>
</template>

<script>

export default {
  name: 'TwitterFeed',
  data () {
      return {
        twitterLink: 'https://rapid.sawbo-animations.org/sawbo-twitter.html',
      }
  },
  mounted() {
    this.twitterLink = 'https://rapid.sawbo-animations.org/sawbo-twitter.html';
  }

}
</script>

<style scoped>
iframe {
  border:none;
  width:100%;
  height: 27rem;
}
</style>
