<template>
    <iframe :src="facebookLink"></iframe>
</template>

<script>

export default {
  name: 'FacebookFeed',
  data () {
      return {
        facebookLink: 'https://rapid.sawbo-animations.org/sawbo-facebook.html',
      }
  },
  mounted() {
    this.facebookLink = 'https://rapid.sawbo-animations.org/sawbo-facebook.html';
  }

}
</script>

<style scoped>
iframe {
  border:none;
  width:100%;
  height: 27rem;
}
</style>
