<template>
    <div class="news-item">
        <div class="news-item-image-cont">
            <a :href="newsLink" target="_blank">
                <img 
                    class="news-item-image" 
                    :src="newsImage"
                    :alt="newsItem.title"
                >
            </a>
        </div>
        <div class="news-item-text">
          <a :href="newsLink" class="news-item-link" target="_blank">
              <h4 class="news-item-title">{{ newsItem.title }}</h4>
          </a>
          <p class="news-item-date">{{ newsDate }}</p>
          <p class="news-item-desc">{{ newsDesc }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NewsItem',
  props: {
    newsItemID: {
        type: String,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
    ...mapGetters([
      'news',
      'months',
    ]),
    newsItem() {
        return this.news[this.newsItemID]
    },
    newsImage() {
        return "https://sawbo-animations.org/media/imgs/" + this.newsItem.image;
    },
    newsDate() {
        let display = '';
        if (this.newsItem && this.newsItem.date) {
            let d = this.newsItem.date;
            display = this.months[Number(d.month)];
            if (d.day) {
                display += ' ' + d.day;
            }
            display += ', ' + d.year;
        }
        return display;
    },
    newsDesc() {
        return this.newsItem.description.slice(0, 150) + "...";
    },
    newsLink() {
        if (this.newsItem.link != "") {
            return this.newsItem.link;
        } else if (this.newsItem.videoLink != "") {
            let v = this.newsItem.videoLink.replace("//www.youtube.com/embed/", "https://youtu.be/");
            return v;
        } else {
            return false;
        }
    }
  },
  created() {
      //console.log('news', this.news);
  }

}
</script>

<style scoped>
.news-item-image-cont {
    width: 100%;
    padding-bottom: 1%;
    display: block;
}

.news-item-image {
    width: 100%;
}

.news-item-text {
    width: 100%;
    padding-bottom: 4%;
    display: block;
}

.news-item-title {
    font-size: 1em;
}
.news-item-link {
    text-decoration: none;
    color: #000;
}
.news-item-link:hover {
    text-decoration: underline;
}

.news-item-date {
    font-size: 1em;
}

.news-item-desc {
    font-size: 1em;
}

@media only screen and (min-width: 745px) {
.news-item-image-cont {
    width: 48%;
    padding-right: 2%;
    display: inline-block;
}
.news-item-image {
    width: 100%;
}

.news-item-text {
    width: 48%;
    padding-left: 2%;
    display: inline-block;
    vertical-align: top;
}

.news-item-date {
    font-size: .8em;
}

.news-item-desc {
    font-size: .8em;
}
}

@media only screen and (min-width: 1200px) {
.news-item-image-cont {
    width: 48%;
    height: 15rem;
    padding-right: 2%;
    display: inline-block;
}
.news-item-image {
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}
}

</style>
