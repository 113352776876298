<template>
    <div class="video-list">
        <video-item 
            v-for="v in videoList" :key="v"
            :videoID="v"
            class="video-item"
        />
    </div>
</template>

<script>
import VideoItem from './VideoItem'

export default {
  name: 'VideoList',
  components: {
      VideoItem,
  },
  props: {
    videoList: {
        type: Array,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
  },
  created() {
  }

}
</script>

<style scoped>
.video-list {
    width: 100%;
}
.video-item {
    width: 100%;
    display: block;
    padding-bottom: 5%;
}

@media only screen and (min-width: 745px) {
.video-item {
    width: 50%;
    display: inline-block;
    vertical-align:top;
}
}
</style>
