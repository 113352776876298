<template>
    <div class="news-item-list">
        <news-item 
            v-for="n in newsItemList" :key="n"
            :newsItemID="n"
            class="news-item"
        />
    </div>
</template>

<script>
import NewsItem from './NewsItem'

export default {
  name: 'NewsItemList',
  components: {
      NewsItem,
  },
  props: {
    newsItemList: {
        type: Array,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
  },
  created() {
      //console.log(this.newsItemList);
  }

}
</script>

<style scoped>
.news-item-list {
    width: 100%;
}
.news-item {
    width: 100%;
    display: inline-block;
    padding-bottom: 5%;
}

@media only screen and (min-width: 1200px) {
  .news-item {
        width: 50%;
        display: inline-block;
        padding-bottom: 5%;
    }
}

</style>
