<template>
    <div>
        <ul class="resource-list">
          <li>
            <a href="https://coronavirus.jhu.edu/">
              <img src="../../assets/jhu_covid_19_generic.png" alt="Johns Hopkins" class="resource-img">
            </a>
          </li>
          <li>
            <a href="https://ihp.msu.edu/index.php/covid-19-resources">
              <img src="../../assets/MSUwordmark.png" alt="Michigan State University" class="resource-img">
            </a>
          </li>
          <li>
            <a href="https://www.usaid.gov/coronavirus">
              <img src="../../assets/USAID_Horizontal_RGB_294.png" alt="USAID" class="resource-img">
            </a>
          </li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'CovidResources',
  data () {
      return {
      }
  },
  methods: {
  }
}
</script>

<style scoped>
.resource-list {
    width: 100%;
    list-style: none;
}
.resource-list li {
    width: 100%;
    display: block;
    padding-bottom: 5%;
}
.resource-list li a {
  /*color: #3B5783;*/
  width: 100%;
  color: #000000;
}
.resource-img {
    width: 100%;
}

@media only screen and (min-width: 745px) {
.resource-list li {
    width: 32%;
    padding-right: 1%;
    display: inline-block;
}
.resource-img {
    width: 95%;
}
}
</style>
