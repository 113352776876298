<template>
    <div class="SawboRapidFooter">
        <div class="disclaimer-text">
          <i>The information provided on this Web site is not official U.S. Government information and does not represent the views or positions of the U.S. Agency for International Development or the U.S. Government.</i>
        </div>
        <div class="footer-logos">
          <div class="footer-logo-cont">
            <img 
              src="../../assets/USAID_Horizontal_RGB_294.png"
              class="footer-logo usaid-logo"
            >
          </div>
          <div class="footer-logo-cont">
            <img 
              src="../../assets/MSUwordmark.png"
              class="footer-logo msu-logo">
          </div>
          <div class="footer-logo-cont">
            <a href="https://sawbo-animations.org" target="_blank">
              <img 
                src="../../assets/SAWBO_Logo_500px.jpg"
                class="footer-logo sawbo-logo">
            </a>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SawboRapidFooter',
  props: {
  },
  data () {
      return {
      }
  },
  computed: {
    currentYear() {
      let d = new Date();
      return d.getFullYear();
    }
  }
}
</script>

<style scoped>
.SawboRapidFooter {
  width: 100%;
  padding: 2% 5%;
  border-top: 2px solid #fafafa;
}
.disclaimer-text {
  color: #414141;
  font-size: .8em;
  text-align: justify;
}
.footer-logos {
  width: 100%;
  padding-top: 1%;
}
.footer-logo-cont {
  width: 33%;
  display: inline-block;
  padding: 0;
}
.footer-logo {
  width: 100%;
}
.msu-logo {
  width: 95% !important;
  margin-left: 5%;
  float: left;
}
.sawbo-logo {
  width: 80% !important;
  float: right;
  margin-right: 2%;
}
</style>
