<template>
  <div class="SawboRapidHeader">
      <div class="ftf-logo-cont">
        <img 
          src="../../assets/Feed the future_horizontal blue.jpg" 
          class="ftf-logo"
          >
      </div>
      <div class="rapid-wordmark">SAWBO <i>RAPID</i></div>
      <nav class="rapid-bar-menu">
          <router-link class="rapid-bar-menu-link" to="/">Home</router-link>
          <router-link class="rapid-bar-menu-link" to="/about">About</router-link>
          <router-link class="rapid-bar-menu-link" to="/news">News</router-link>
          <router-link class="rapid-bar-menu-link" to="/presentations">Presentations</router-link>
          <router-link class="rapid-bar-menu-link" to="/videos">Videos</router-link>
      </nav>
  </div>
</template>

<script>

export default {
  name: "SawboRapidHeader",
  props: {},
  data() {
    return {};
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style scoped>

.SawboRapidHeader {
  width: 100%;
  padding: 2% 0;
}

.ftf-logo-cont {
  padding: 0 5%;
  border-bottom: 2px solid #fafafa;
}

.ftf-logo {
  width: 35%;
}

.rapid-wordmark {
  padding: 0 5%;
  color: #3B5783;
  font-size: 3em;
  font-weight: bold;
}

.rapid-bar-menu {
  padding: .5% 5%;
  background-color: #3B5783;
  color: #ffffff;
  text-align: right;
}

.rapid-bar-menu-link {
  color: #ffffff;
  text-decoration: none;
  padding-left: 15px;
}

.rapid-bar-menu-link:hover {
  text-decoration: underline;
}

</style>
