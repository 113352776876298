import { render, staticRenderFns } from "./TwitterFeed.vue?vue&type=template&id=04ff2bd4&scoped=true&"
import script from "./TwitterFeed.vue?vue&type=script&lang=js&"
export * from "./TwitterFeed.vue?vue&type=script&lang=js&"
import style0 from "./TwitterFeed.vue?vue&type=style&index=0&id=04ff2bd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04ff2bd4",
  null
  
)

export default component.exports