<template>
  <div class="page">
    <h2>News</h2><br>
      <news-list
        :newsItemList="newsList"
      />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import NewsList from '../components/news/NewsList'

export default {
  name: 'News',
  metaInfo() {
    return {
      title: "SAWBO RAPID News",
      meta: [
        {
          name: "description",
          content: "Find recent news articles related to SAWBO RAPID."
        },
        { property: "og:title", content: "SAWBO RAPID News" },
        { property: "og:image", content: "https://sawbo-animations.org/images/social_media/SAWBO_FB_Share.png" },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "website" }
      ],
    };
  },
  components: {
    NewsList,
  },
  data() {
      return {
      }
  },
  computed: {
      ...mapGetters([
        'newsList',
      ]),
  },
  methods: {
  },
  created() {
  }
}
</script>

<style scoped>
</style>
