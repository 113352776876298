<template>
    <div class="SawboRapidFooter">
        <div class="contact-text">
          Contact us at <a href="mailto:contact@sawbo-animations.org">contact@sawbo-animations.org</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
  },
  data () {
      return {
      }
  },
  computed: {
  }
}
</script>

<style scoped>
.SawboRapidFooter {
  width: 100%;
  padding: 2% 5%;
  /*border-top: 2px solid #fafafa;*/
}
.contact-text {
  width: 100%;
  font-size: .8em;
  text-align: center;
}
.contact-text a {
    color: #3B5783;
}
</style>
