const news = {
    "221": {
        "id": "221",
        "title": "Agricultural Research Responses in Times of Crisis",
        "description": "Feed the Future has quickly adapted innovations like Scientific Animations Without Borders, a platform that delivers educational animated videos, for the COVID-19 crisis.",
        "category": "News",
        "link": "https://www.feedthefuture.gov/article/agricultural-research-responses-in-times-of-crisis/",
        "videoLink": null,
        "source": "Feed the Future",
        "image": "Agricultural Research Responses in Times of Crisis_600x400.jpg",
        "date": {
            "year": "2020",
            "month": "6",
            "day": "25"
        }
    },
    "243": {
        "id": "243",
        "title": "SAWBO RAPID Addresses Economic Impacts of COVID-19",
        "description": "When COVID-19 hit her home country of Kenya, Rachel Olukhanda Lutomia was working as a salesperson for a clothing business. As restrictions and social distancing guidelines stiffened, Lutomia knew her sales job was not going to provide enough money to support her family of five through the pandemic.  Lutomia had been a part-time hobby farmer prior to COVID-19. She struggled to maintain a modest garden while working and caring for her family but had little success with her crops. While watching local television channel KTN Farmers TV, Rachel saw an animation, created by Scientific Animations Without Borders (SAWBO), on the subject of raised bed farming.",
        "category": "News",
        "link": "https://www.thechicagocouncil.org/commentary-and-analysis/blogs/sawbo-rapid-addresses-economic-impacts-covid-19",
        "videoLink": null,
        "source": "The Chicago Council",
        "image": "RachelLutomia_600x400.jpg",
        "date": {
            "year": "2020",
            "month": "9",
            "day": "22"
        }
    },
    "244": {
        "id": "244",
        "title": "SAWBO announces new educational intervention program",
        "description": "Scientific Animations Without Borders (SAWBO) introduces a new program, funded through USAID's Feed the Future initiative, that offers educational interventions to disseminate crucial information related to COVID-19's secondary economic impacts, including disruption to trade, supply chains, and markets. ",
        "category": "Announcements",
        "link": "https://mailchi.mp/sawbo-animations/sawbo-announces-new-educational-intervention-program-rh50cz93vk?e=1dff2bc585",
        "videoLink": null,
        "source": "SAWBO",
        "image": "SAWBORAPIDAnnouncement_600x400.jpg",
        "date": {
            "year": "2020",
            "month": "9",
            "day": "18"
        }
    },
    "297": {
        "id": "297",
        "title": "Precision Development to Solve Wicked Problems",
        "description": "Scientific Animations Without Borders (SAWBO) uses the information highway to deliver food security results during the COVID-19 pandemic.",
        "category": "News",
        "link": "https://www.thechicagocouncil.org/commentary-and-analysis/blogs/precision-development-solve-wicked-problems",
        "videoLink": null,
        "source": "The Chicago Council",
        "image": "SAWBO app_01.jpg",
        "date": {
            "year": "2021",
            "month": "3",
            "day": "30"
        }
    },
    "300": {
        "id": "300",
        "title": "SAWBO RAPID Featured in Global Blog",
        "description": "The Chicago Council on Global Affairs recently highlighted SAWBO's efforts to use \"the information highway to deliver food security results during the COVID-19 pandemic.\"",
        "category": "News",
        "link": "https://mailchi.mp/sawbo-animations/sawbo-rapid-featured-in-global-blog-7dvum85m80",
        "videoLink": null,
        "source": "SAWBO RAPID",
        "image": "chicago_council_2021.jpg",
        "date": {
            "year": "2021",
            "month": "4",
            "day": "20"
        }
    },
    "302": {
        "id": "302",
        "title": "Farmers learn jerrycan technology to store beans upon harvesting",
        "description": "The war against post-harvest loss seemingly may soon have a major victory with your help, as more simple and cost-effective measures continue to be adopted in the value chain. I learned of jerrycan bean storage technology that is gaining popularity in Kenya courtesy of the Feed the Future Scientific Animations Without Borders Responsive Adaptive Participatory Information Dissemination Program or SAWBO RAPID as it is known, a program made possible with support from USAID and based at Michigan State University and Purdue University in the United States (U.S.).",
        "category": "Blog Posts",
        "link": "https://saccoreview.co.ke/farmers-learn-jerrycan-technology-to-store-beans-upon-harvesting/",
        "videoLink": null,
        "source": "Sacco Review",
        "image": "Different-grains-stored-in-locally-availlabl-e-containers-by-Bulechia-Commuity-Photo-by-Kataru-Concepts_600x400.jpg",
        "date": {
            "year": "2021",
            "month": "4",
            "day": "28"
        }
    },
    "303": {
        "id": "303",
        "title": "Post harvest loss: Farmers embrace jerrycan technology to store beans",
        "description": "Using animations in local languages with familiar imagery and collaborating with local partners, The SAWBO RAPID project identifies critical food security topics and delivers knowledge to mitigate COVID-19's secondary economic impacts, including disruption to trade, supply chains, and markets (Animation project seeks to lessen COVID-19 secondary impacts with series of videos)",
        "category": "Blog Posts",
        "link": "https://hortfreshjournal.com/post-harvest-loss-farmers-embrace-jerrycan-technology-to-store-beans/",
        "videoLink": null,
        "source": "Hortfresh",
        "image": "Communities-adopt-the-Jerrycan-Technlgy-to-store-grains-PHOTO-COURTESY-600x400.jpg",
        "date": {
            "year": "2021",
            "month": "4",
            "day": "29"
        }
    },
    "304": {
        "id": "304",
        "title": "JERRYCAN STORAGE INNOVATION; A GAME CHANGER IN POST HARVEST LOSES",
        "description": "The video explains how bruchids, insects that attack beans, infect bean supplies. The insectsu2019 boreholes on the beans reducing their weight, quality, and chances of germination that allow the beans to grow. Jerrycan bean storage emerges to counter the challenge.",
        "category": "Blog Posts",
        "link": "https://www.talkafrica.co.ke/jerrycan-storage-innovation-a-game-changer-in-post-harvest-loses/",
        "videoLink": null,
        "source": "Talk Africa",
        "image": "jerrycan_beans_comp_600x400.jpg",
        "date": {
            "year": "2021",
            "month": "4",
            "day": "28"
        }
    },
    "308": {
        "id": "308",
        "title": "TIME FOR FARMERS TO PLANT SEED THEY STORED IN JERRYCANS",
        "description": "Using animations in local languages with familiar imagery and collaborating with local partners, The SAWBO RAPID project identifies critical food security topics and delivers knowledge to mitigate COVID-19u2019s secondary economic impacts, including disruption to trade, supply chains and markets.",
        "category": "Blog Posts",
        "link": "https://kataruconcepts.com/2021/05/06/time-for-farmers-to-plant-seed-they-stored-in-jerrycans/",
        "videoLink": null,
        "source": "KATARU Concepts",
        "image": "Different-grains-stored-in-locally-availlabl-e-containers-by-Bulechia-Commuity-Photo-by-Kataru-Concepts_600x400 (1).jpg",
        "date": {
            "year": "2021",
            "month": "5",
            "day": "6"
        }
    },
    "313": {
        "id": "313",
        "title": "COVID-19 Food Security Workshop Ghana",
        "description": "SAWBO RAPID is an educational intervention program that disseminates crucial food-security information in response to COVID-19's secondary economic impacts including disruption to trade, supply chains, and markets through the creation of animated videos targeted to reach high-risk populations.",
        "category": "Announcements",
        "link": "https://mailchi.mp/sawbo-animations/sawbo-covid-19-food-security-virtual-workshop-ghana-wamlxokro1",
        "videoLink": null,
        "source": "SAWBO RAPID",
        "image": "78696223-f296-d7ee-334e-4790fb45478e copy.jpg",
        "date": {
            "year": "2021",
            "month": "6",
            "day": "21"
        }
    },
    "315": {
        "id": "315",
        "title": "COVID-19 Food Security Series of Virtual Workshops - NIGERIA",
        "description": "SAWBO RAPID is an educational intervention program that disseminates crucial food-security information in response to COVID-19's secondary economic impacts including disruption to trade, supply chains, and markets through the creation of animated videos targeted to reach high-risk populations.",
        "category": "Announcements",
        "link": "https://mailchi.mp/sawbo-animations/covid-19-food-security-series-of-virtual-workshops-nigeria-z5l6jaffo5",
        "videoLink": null,
        "source": "SAWBO RAPID",
        "image": "78696223-f296-d7ee-334e-4790fb45478e copy_2.jpg",
        "date": {
            "year": "2021",
            "month": "7",
            "day": "13"
        }
    },
    "316": {
        "id": "316",
        "title": "Olagoke Akinlabi, USAID Nigeria Mission Project Management Specialist",
        "description": "Olagoke Akinlabi has spent over a decade volunteering and working for various Nigerian development organizations. Over the last five years he has worked on different projects while at the USAID Nigeria Mission. In his current position with the Mission, he manages the Partnership for Inclusive Agricultural Transformation in Africa and the Building Sustainable Livelihoods activities. He also serves as the Mission point of contact for the Feed the Future SAWBO RAPID Scaling Activity.",
        "category": "Your & My Impact",
        "link": "https://mailchi.mp/sawbo-animations/your-impact-olagoke-akinlabi-rxvuz5uv9k",
        "videoLink": null,
        "source": "SAWBO RAPID",
        "image": "Goke_600x400_02.jpg",
        "date": {
            "year": "2021",
            "month": "7",
            "day": "7"
        }
    }
}

const newsOrder = Object.keys(news);

const presentations = {
    "14": {
        "category": "Presentations",
        "description": "Presentation about raising mass awareness through partnerships and SAWBO",
        "file": "200715 SAWBO FAW BD.pptx",
        "id": "14",
        "image": "faw_presentation_thumbnail.jpg",
        "source": "Timothy J. Krupnik, CIMMYT",
        "title": "Scaling-out awareness of Fall Armyworm IPM: Experiences in Bangladesh with SAWBO"
    },
    "15": {
        "category": "Presentations",
        "description": "Presentation about the new SAWBO RAPID Program.",
        "file": "SAWBO_Missions_July_15_2020_FINAL.pptx",
        "id": "15",
        "image": "sawbo_rapid_presentation_thumbnail.jpg",
        "source": "SAWBO",
        "title": "Feed The Future SAWBO RAPID"
    }
}

const videos = {
    "944": {
        "id": "944",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Asante Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/zmbP6MuLQnk",
        "filename": "AKA_Asante Twi_Ghana_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-09 04:57:44"
    },
    /*"948": {
        "id": "948",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Bamanankan",
        "country": "Mali",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/SIoDgVfM2bU",
        "filename": "BA_Bamanankan_Mali_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-11 09:19:14"
    },*/
    "949": {
        "id": "949",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Bengali",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/Y_Uk7ocqN9Q",
        "filename": "BEN_Bengali_Bangladesh_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-11 09:47:00"
    },
    "941": {
        "id": "941",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Dagbani",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/uc0oYL04KWk",
        "filename": "DAG_Dagbani_Ghana_PHLJerrycan_Final1",
        "files": [],
        "publishDate": "2020-08-06 10:02:32"
    },
    "945": {
        "id": "945",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Dangme",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/0EmqNfE_zdg",
        "filename": "ADA_Dangme_Ghana_PHLJerrycan_Final",
        "files": [],
        "publishDate": "2020-08-09 05:09:32"
    },
    "946": {
        "id": "946",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/0EmqNfE_zdg",
        "filename": "EN10_English_Ghana_PHLJerryCan_covid_Final",
        "files": [],
        "publishDate": "2020-08-09 16:58:47"
    },
    "947": {
        "id": "947",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Fante",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/cPHjMaZZtk0",
        "filename": "AKA1_Fante_Ghana_PHLJerryCan_covid_Final",
        "files": [],
        "publishDate": "2020-08-09 17:03:38"
    },
    /*"950": {
        "id": "950",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Fon",
        "country": "Benin",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/DN4Sz4_J84Y",
        "filename": "FON_Fon_Benin_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-11 09:56:20"
    },
    "952": {
        "id": "952",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Italian",
        "country": "Italy",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/Lv0bPB6vCkQ",
        "filename": "IT_Italian_Italy_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-12 17:10:08"
    },
    "953": {
        "id": "953",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kannada",
        "country": "India",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/ejgw55Q4P0c",
        "filename": "KN_Kannada_India_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-12 17:15:02"
    },
    "954": {
        "id": "954",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Portuguese",
        "country": "Mozambique",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/vf5iFhifRbg",
        "filename": "PT2_Portuguese_Mozambique_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-13 12:50:21"
    },*/
    "151": {
        "id": "151",
        "topic": "Neem",
        "category": "Agriculture",
        "language": "Spanish",
        "country": "Venezuela",
        "subtitles": null,
        "title": "Neem",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/dMBs7hNVIkQ",
        "filename": "ES8_Spanish_Venezuela_PHLJerrycan_covid_Final",
        "files": [],
        "publishDate": "2020-08-12 17:00:02"
    }
}

const topics = {
    /*"About": {
        "id": "About",
        "title": "About Scientific Animations Without Borders (SAWBO&#8482;)",
        "description": "Scientific Animations Without Borders (SAWBO&#8482;) is a University of Illinois Urbana-Champaign based initiative focused on the development of educational content for people around the planet. We create animations and place them into local languages and SAWBO&#8482; videos can be downloaded for free and used by other groups in their own local educational programs in the field. Animations are available in a variety of file formats including for cell phones.",
        "category": "SAWBO",
        "shortTitle": "About SAWBO",
        "image": "About.jpg",
        "script": true,
        "metrics": true,
        "releaseDate": "2014-01-01",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": true,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": true,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [1, 4]
    },*/
    "Neem": {
        "id": "Neem",
        "title": "Natural Insecticide from Neem Seeds Natural Insecticide from Neem Seeds",
        "description": "Naturally occurring insecticidal compounds can be extracted from neem seeds and sprayed onto crops to prevent pest insect damage. This animation explains how to sort neem fruits, dry them, remove their outer shells, sort the seeds, grind them, mix the powder in water, filter the mixture, and then prepare the solution necessary for spraying on the crops.",
        "category": "Agriculture",
        "shortTitle": "Natural Insecticide from Neem",
        "image": "Neem.jpg",
        "script": true,
        "metrics": true,
        "releaseDate": "2014-01-01",
        "sdg": {
            "1": true,
            "2": true,
            "3": false,
            "4": true,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": true,
            "11": false,
            "12": true,
            "13": false,
            "14": false,
            "15": true,
            "16": false,
            "17": false
        },
        "videos": [151]
    },
    /*"Ad-C_General": {
        "id": "Ad-C_General",
        "title": "SAWBO: Scientific Animations Without Borders",
        "description": "This video is a short explanation of how SAWBO improves people's lives through spreading knowledge. It focuses on how we spread knowledge about women's empowerment issues.",
        "category": "SAWBO",
        "shortTitle": "",
        "image": "Ad-C_General.jpg",
        "script": false,
        "metrics": true,
        "releaseDate": "",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [433]
    },
    "NEWSVideo_DengueTonga": {
        "id": "NEWSVideo_DengueTonga",
        "title": "Animation for Dengue Outbreak in Tonga",
        "description": "In February 2015, a dengue fever outbreak was declared in Tonga. Working with the Ministry of Health of Tonga, SAWBO took a Dengue Prevention animation that already existed in their video library and adapted it to apply to the Dengue outbreak.",
        "category": "SAWBO",
        "shortTitle": "",
        "image": "NEWSVideo_DengueTonga.jpg",
        "script": false,
        "metrics": true,
        "releaseDate": "",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [438]
    },
    "AndroidAppUse": {
        "id": "AndroidAppUse",
        "title": "How to Use the SAWBO Deployer App",
        "description": "This animation explains how someone can use the SAWBO Deployer app to spread knowledge to people in developing nations. This animation talks about all of the features of our app and shows examples of how it can be used in the field.",
        "category": "SAWBO",
        "shortTitle": "",
        "image": "AndroidAppUse.jpg",
        "script": false,
        "metrics": true,
        "releaseDate": "",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [619]
    },*/
    "PHLJerryCanFTF": {
        "id": "PHLJerryCanFTF",
        "title": "Postharvest Loss: Jerrycan Bean Storage (FTF)",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "category": "Agriculture",
        "shortTitle": null,
        "image": "PHLJerryCan.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2020-08-07",
        "sdg": {
            "1": true,
            "2": true,
            "3": false,
            "4": true,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": true,
            "11": false,
            "12": true,
            "13": false,
            "14": false,
            "15": true,
            "16": false,
            "17": false
        },
        "videos": [
            944,
            949,
            941,
            945,
            946,
            947
        ]
    }
}

export default {
    news,
    newsOrder,
    presentations,
    videos,
    topics
}