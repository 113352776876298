<template>
  <div class="page">
      Disclaimer
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Disclaimer',
  metaInfo() {
    return {
      title: "Disclaimer",
      meta: [
        {
          name: "description",
          content: ""
        },
        { property: "og:title", content: "Disclaimer" },
        { property: "og:image", content: "https://sawbo-animations.org/images/social_media/SAWBO_FB_Share.png" },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "website" }
      ],
    };
  },
  components: {
  },
  data() {
      return {
      }
  },
  computed: {
      ...mapGetters([
      ]),
  },
  methods: {
  },
  created() {
  }
}
</script>

<style scoped>
</style>
