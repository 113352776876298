<template>
    <div class="presentation-item">
        <div class="presentation-item-image-cont">
            <a :href="presentationLink" target="_blank">
            <img 
                class="presentation-item-image" 
                :src="presentationImage"
                :alt="presentation.title"
            ></a>
        </div>
        <div class="presentation-item-text">
          <a :href="presentationLink" class="presentation-item-link" target="_blank">
              <h4 class="presentation-item-title">{{ presentation.title }}</h4>
          </a>
          <p class="presentation-item-desc">{{ presentationDesc }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PresentationItem',
  props: {
    presentationID: {
        type: String,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
    ...mapGetters([
      'presentations',
      'months',
    ]),
    presentation() {
        return this.presentations[this.presentationID]
    },
    presentationImage() {
        if (this.presentation.image.includes("https:")) {
            return this.presentation.image;
        } else {
            return "https://sawbo-animations.org/documents/images/" + this.presentation.image;
        }
    },
    presentationDesc() {
        //return this.presentation.description.slice(0, 150) + "...";
        return this.presentation.description;
    },
    presentationLink() {
        if (this.presentation.link && this.presentation.link.includes("http")) {
            return this.presentation.link;
        } else if (this.presentation.file) {
            return "https://sawbo-animations.org/documents/files/" + this.presentation.file;
        } else {
            return false;
        }
    }
  },
  created() {
  }

}
</script>

<style scoped>
.presentation-item {
    border-bottom: 1px solid #3B5783;
    padding: 4% 0;
}
.presentation-item-image-cont {
    /*width: 10%;
    padding-right: 1%;
    display: inline-block;*/
    display: none;
}

.presentation-item-image {
    width: 100%;
}

.presentation-item-text {
    width: 100%;
    display: inline-block;
}

.presentation-item-title {
    font-size: 1em;
}
.presentation-item-link {
    text-decoration: none;
    color: #000;
}
.presentation-item-link:hover {
    text-decoration: underline;
}

.presentation-item-desc {
    font-size: 1em;
}

@media only screen and (min-width: 745px) {
.presentation-item {
    border-bottom: none;
}
.presentation-item-image-cont {
    width: 33%;
    padding-right: 2%;
    display: inline-block;
}
.presentation-item-image {
    width: 100%;
}

.presentation-item-text {
    width: 65%;
    display: inline-block;
    vertical-align: top;
}

.presentation-item-date {
    font-size: .8em;
}

.presentation-item-desc {
    font-size: .8em;
}
}

/*
@media only screen and (min-width: 1200px) {
.news-item-image-cont {
    width: 48%;
    height: 15rem;
    padding-right: 2%;
    display: inline-block;
}
.news-item-image {
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}
}*/

</style>
