<template>
  <div class="page">
      <section class="news" v-if="hasNews">
        <h2>News</h2><br>
        <news-list
          :newsItemList="homeNews"
        />
        <div class="see-all">
          <router-link class="see-all-link" to="/news">See all >>></router-link>
        </div>
      </section>
      <hr class="blue-hr" v-if="hasVideos">
      <section class="videos" v-if="hasVideos">
        <h2>Videos</h2><br>
        <video-list
          :videoList="homeVideos"
        />
        <div class="see-all">
          <router-link class="see-all-link" to="/videos">See all >>></router-link>
        </div>
      </section>
      <hr class="blue-hr">
      <section class="about">
        <h2>COVID-19 Resources</h2>
        <p>Many resources are available on COVID-19. Visit the links below for more information.</p>
        <br>
        <covid-resources />
      </section>
      <hr class="blue-hr">
      <section class="about">
        <facebook-feed class="social-media-feed facebook-feed" />
        <twitter-feed class="social-media-feed twitter-feed" />
      </section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import NewsList from '../components/news/NewsList'
import VideoList from '../components/video/VideoList'
import TwitterFeed from '../components/socialMedia/TwitterFeed'
import FacebookFeed from '../components/socialMedia/FacebookFeed'
import CovidResources from '../components/utils/CovidResources'

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: "SAWBO RAPID",
      meta: [
        {
          name: "description",
          content: "Scientific Animations Without Borders (SAWBO) announces the Feed the Future SAWBO Responsive-Adaptive-Participatory Information Dissemination Scaling Program (SAWBO RAPID)."
        },
        { property: "og:title", content: "SAWBO RAPID" },
        { property: "og:image", content: "https://sawbo-animations.org/images/social_media/SAWBO_FB_Share.png" },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "website" }
      ],
    };
  },
  components: {
    NewsList,
    VideoList,
    TwitterFeed,
    FacebookFeed,
    CovidResources
  },
  data() {
      return {
      }
  },
  computed: {
      ...mapGetters([
        'homeNews',
        'homeVideos',
        'homePresentations'
      ]),
      hasNews() {
        if (this.homeNews.length > 0) {
          return true;
        }
        return false;
      },
      hasVideos() {
        if (this.homeVideos.length > 0) {
          return true;
        }
        return false;
      },
      hasPresentations() {
        if (this.homePresentations.length > 0) {
          return true;
        }
        return false;
      },
  },
  methods: {
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style scoped>

.about {
  padding-bottom: 3%;
}
.blue-hr {
  color: #3B5783;
  margin: 4% 0;
}
.about h2 {
  padding-bottom: 1.5%;
}
.social-media-feed {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}
.see-all {
  display: block;
}
.see-all-link {
  text-align: right;
  color: #000000;
  text-decoration: none;
  float:right;
  font-size: 1.1em;
}
.see-all-link:hover {
  text-decoration: underline;
}
@media only screen and (min-width: 745px) {
  .social-media-feed {
    width: 49%;
  }
  .twitter-feed {
    padding-left: 1%;
  }
  .facebook-feed {
    padding-right: 1%;
  }
}
</style>
